export default (...props) => toUrlEncoded(...props)

export const toUrlEncoded = (element, key, list) => {
  var newList = list || []
  if (typeof element == "object") {
    for (var idx in element)
      toUrlEncoded(element[idx], key ? key + "[" + idx + "]" : idx, newList)
  } else {
    newList.push(key + "=" + encodeURIComponent(element))
  }
  return newList.join("&")
}
