import { graphql, navigate } from 'gatsby';
import React from 'react';
import { Button, CustomInput, FormGroup, FormText, Input, Label, Spinner } from 'reactstrap';

import OrderWrapper from '../components/orderWrapper';
import utilUrlEncode from '../utils/toUrlEncoded';

export default class Order extends React.Component {
  state = {
    ...this.props.location.state,
    step: 4,
    form: {
      isValidated: false,
      wait: false,
      novinkyEmailem: true,
      ...(this.props.location.state && this.props.location.state.form),
      aresStav: null,
      aresIsLoadingData: false,
    },
  }

  loadAresData(event) {
    if (event.target.value.length === 8) {
      const thisClassComponent = this

      thisClassComponent.setState(prevState => ({
        form: {
          ...prevState.form,
          aresIsLoadingData: true,
          aresStav: null,
        },
      }))

      window
        .fetch("https://www.html-factory.cz/wp-admin/admin-ajax.php", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: utilUrlEncode({
            ico: event.target.value,
            action: "ares",
          }),
        })
        .then(response => response.json())
        .then(function(response) {
          if (thisClassComponent.mounted)
            thisClassComponent.setState(prevState => ({
              form: {
                ...prevState.form,
                ...response,
                aresIsLoadingData: false,
              },
            }))
        })
        .catch(() => {
          if (thisClassComponent.mounted)
            thisClassComponent.setState(prevState => ({
              form: {
                ...prevState.form,
                aresIsLoadingData: false,
                aresStav: "Nastala chyba, zkuste to prosím později",
              },
            }))
        })
    }
  }

  formSubmitHandler(event) {
    event.preventDefault()
    if (event.target.checkValidity() === false) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          isValidated: true,
        },
      }))
    } else {
      if (this.state.form.humanCheck) {
        alert("Byl jste detekován jako robot. Objednávka nebude odeslána")
        return
      }

      this.setState(prevState => ({
        form: {
          ...prevState.form,
          wait: true,
        },
      }))

      const errorMessage = () =>
        alert(
          "Při zpracování požadavku došlo k chybě. Ověřte správnost údajů a zkuste to prosím znovu."
        )

      window
        .fetch("https://www.html-factory.cz/wp-admin/admin-ajax.php", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: utilUrlEncode({
            action: "order",
            ...this.state,
          }),
        })
        .then(response => {
          this.setState(prevState => ({
            form: {
              ...prevState.form,
              wait: false,
            },
          }))

          if (!response.ok) {
            errorMessage()
            throw Error(response.statusText)
          } else {
            navigate("/objednavka-hotovo/")
          }
          return response.json()
        })
        .then(function(response) {
          //console.log(response)
          if (response.status !== "ok") {
            errorMessage()
          }
        })
        .catch(function(error) {
          errorMessage()
          //console.log(error)
        })
    }
  }

  handleChangeInput(event) {
    const name = event.target.name
    const value = event.target.value
    const isCheckbox = event.target.type === "checkbox"

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: isCheckbox ? !prevState.form[name] || false : value,
      },
    }))
  }

  componentDidMount() {
    this.mounted = true

    if (!this.state.service || !this.state.subService)
      return navigate("/objednavka/")
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    if (!this.state.service || !this.state.subService)
      return <OrderWrapper state={this.state} isNextStepDisabled={true} />

    console.log(this.state)

    return (
      <OrderWrapper state={this.state}>
        <h2 className="text-center mb-4 sr-only">Shrnutí objednávky</h2>

        <div className="row mt-n4">
          <div className="col-lg-5 col-xl-4 pt-4">
            <div className="px-3 py-4 p-sm-4 bg-white rounded">
              <h3 className="mb-3">Shrnutí</h3>

              <ul className="list-unstyled border-top">
                <li className="d-flex justify-content-between py-2 border-bottom">
                  <strong>Služba:</strong>
                  <span className="d-block ml-3 text-right">
                    {`${this.state.service.name} ${this.state.subService.name}`}
                  </span>
                </li>
                <li className="d-flex justify-content-between py-2 border-bottom">
                  <strong>Počet typových stránek:</strong>
                  <span className="d-block ml-3 text-right">
                    {this.state.priceSummary.numberOfPages}
                  </span>
                </li>
                <li className="d-flex justify-content-between py-2 border-bottom">
                  <strong>Náročnost:</strong>
                  <span className="d-block ml-3 text-right">
                    {this.state.priceSummary.difficultyName}
                  </span>
                </li>
                <li className="d-flex justify-content-between py-2 border-bottom align-items-end">
                  <strong>Celková cena:</strong>
                  <span className="d-flex ml-3 flex-column text-right">
                    <strong>
                      {this.state.priceSummary.priceAfterModified} Kč
                    </strong>
                  </span>
                </li>
                <li className="d-flex justify-content-between py-2 border-bottom">
                  <strong>Doba realizace:</strong>
                  <span className="d-block ml-3 text-right">
                    do {this.state.priceSummary.realizationDuration}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 pt-4">
            <div className="px-3 py-4 p-sm-4 bg-white rounded">
              <form
                noValidate
                onSubmit={event => this.formSubmitHandler(event)}
                className={this.state.form.isValidated ? "was-validated" : ""}
              >
                <FormGroup tag="fieldset">
                  <h3 className="mb-3">Kontaktní údaje</h3>

                  <div className="row">
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="jmeno">Jméno a příjmení</Label>
                        <Input
                          type="text"
                          name="jmeno"
                          id="jmeno"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.jmeno || ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12"></div>
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.email || ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="tel">Tel.</Label>
                        <Input
                          type="tel"
                          name="tel"
                          id="tel"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.tel || ""}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </FormGroup>

                <fieldset
                  className="form-group"
                  disabled={this.state.form.aresIsLoadingData}
                >
                  <h3 className="mb-3">Fakturační údaje</h3>

                  <div className="row">
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="ic">IČ</Label>
                        <Input
                          type="number"
                          name="ic"
                          id="ic"
                          required
                          onChange={event => {
                            this.handleChangeInput(event)
                            this.loadAresData(event)
                          }}
                          pattern=".{8}"
                          value={this.state.form.ic || ""}
                        />
                        <Input
                          type="hidden"
                          name="dic"
                          id="dic"
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.dic || ""}
                        />
                        <small className="form-text text-muted">
                          Zadejte IČ, ostatní údaje se vyplní automatický.
                        </small>
                        {this.state.form.aresStav && (
                          <small className="text-danger">
                            {this.state.form.aresStav}
                          </small>
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-lg-12 col-xl-6 align-self-center">
                      {this.state.form.aresIsLoadingData && (
                        <FormGroup className="d-flex align-items-center">
                          <div
                            class="spinner-grow mr-3"
                            role="status"
                            style={{ width: "50px", height: "50px" }}
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <small>Načítám údaje z ARES...</small>
                        </FormGroup>
                      )}
                    </div>
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="spolecnost">
                          Celé jméno / název společnosti
                        </Label>
                        <Input
                          type="text"
                          name="spolecnost"
                          id="spolecnost"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.spolecnost || ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="mesto">Město</Label>
                        <Input
                          type="text"
                          name="mesto"
                          id="mesto"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.mesto || ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="ulice">Ulice a číslo popisne</Label>
                        <Input
                          type="text"
                          name="ulice"
                          id="ulice"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.ulice || ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="psc">PSČ</Label>
                        <Input
                          type="text"
                          name="psc"
                          id="psc"
                          required
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.psc || ""}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </fieldset>

                <FormGroup tag="fieldset">
                  <h3 className="mb-3">Podrobnosti projektu</h3>

                  <div className="row">
                    <div className="col-sm-6 col-lg-12 col-xl-6">
                      <FormGroup>
                        <Label for="url">Dokumentace nebo design</Label>
                        <Input
                          type="url"
                          name="url"
                          id="url"
                          placeholder="https://url-dokumentu-ke-stazeni.cz"
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.url || ""}
                        />
                        <FormText color="muted" className="font-weight-light">
                          Dokumenty vložte na externí uložiště typu:
                          leteckaposta, ulozto, dropbox apod.
                        </FormText>
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup>
                        <Label for="poznamka">Poznámka</Label>
                        <Input
                          type="textarea"
                          name="poznamka"
                          id="poznamka"
                          rows="4"
                          onChange={event => this.handleChangeInput(event)}
                          value={this.state.form.poznamka || ""}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </FormGroup>

                <hr></hr>

                <Input
                  type="text"
                  name="humanCheck"
                  id="humanCheck"
                  onChange={event => this.handleChangeInput(event)}
                  value={this.state.form.humanCheck || ""}
                  style={{
                    position: "absolute",
                    top: "-9999px",
                    right: "9999px",
                  }}
                />

                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id="souhlas_pravidla_gdpr"
                    name="souhlas_pravidla_gdpr"
                    label="Souhlasím s pravidly zpracování osobních údajů"
                    onChange={event => this.handleChangeInput(event)}
                    checked={this.state.form.souhlas_pravidla_gdpr || false}
                    required
                  />
                  <CustomInput
                    type="checkbox"
                    id="novinkyEmailem"
                    name="novinkyEmailem"
                    label="Mám zájem o novinky emailem"
                    onChange={event => this.handleChangeInput(event)}
                    checked={this.state.form.novinkyEmailem}
                  />
                </FormGroup>

                <div className="d-flex flex-column d-sm-block">
                  <Button
                    size="lg"
                    color="success"
                    className="px-5"
                    disabled={this.state.form.wait}
                  >
                    {this.state.form.wait && (
                      <Spinner size="lg" className="position-absolute" />
                    )}
                    <span style={{ opacity: this.state.form.wait ? 0 : 1 }}>
                      Odeslat objednávku
                    </span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </OrderWrapper>
    )
  }
}

export const query = graphql`
  query {
    allWordpressWpNoveSluzby(filter: { wordpress_parent: { ne: 0 } }) {
      edges {
        node {
          wordpress_id
          wordpress_parent
          acf {
            s_o_tucny_nadpis
            s_o_normalni_nadpis
            s_o_popis
            s_o_napoveda
            s_o_tag
            projekt_balicky {
              nazev
              casova_narocnost_hlavni_strany
              casova_narocnost_podstrany
              popis
            }
          }
        }
      }
    }
  }
`
